import React from 'react';
import { useTranslation } from 'react-i18next';

export const Features = (props) => {
  const { t } = useTranslation();
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>{t('features.mainTitle')}</h2>
        </div>
        <div className="row features-container">
          {props.data
            ? props.data.map((d) => (
                <div key={`${d.id}`} className="col-xs-6 col-md-3">
                  {' '}
                  <img
                    src={`img/features-icons/${d.id}.svg`}
                    alt="SVG Icon"
                    width="48"
                    height="48"
                  />
                  <h3>{t(`features.${d.id}.title`)}</h3>
                  <p>{t(`features.${d.id}.description`)}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
