import { useState } from 'react';
import emailjs from 'emailjs-com';
import React from 'react';
import { useTranslation } from 'react-i18next';

const initialState = {
  name: '',
  email: '',
  message: '',
};
export const Contact = () => {
  const { t } = useTranslation();
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_f4yhm2a', 'template_vn30yv9', e.target, '6tOxUUUZ0A_JNWjjV').then(
      (result) => {
        clearState();
      },
      (error) => {
        console.log(error.text);
      },
    );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{t('contact.mainTitle')}</h2>
                <p>{t('contact.description')}</p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={t('contact.formPlaceholder.name')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={t('contact.formPlaceholder.email')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={t('contact.formPlaceholder.message')}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  {t('contact.sendMessage')}
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3> {t('contact.contactInfo')}</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> {t('contact.address.title')}
                </span>
                <span>{t('contact.address.description')}</span>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a href="tel:+37376070007">
                  <span>
                    <i className="fa fa-phone"></i> {t('contact.phone.title')}
                  </span>{' '}
                  <span>{t('contact.phone.description')}</span>
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a href="mailto:info@schei.ltd">
                  <span>
                    <i className="fa fa-envelope-o"></i> {t('contact.email.title')}
                  </span>{' '}
                  <span>{t('contact.email.description')}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 Schei Ltd.</p>
        </div>
      </div>
    </div>
  );
};
